import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import EnquiryForm from "./EnquiryForm"
import { colors, Modal, Portal } from "social-supermarket-components"
import RoundIconButton from "../../../../../RoundIconButton"

const StyledModal = styled(Modal)`
  && {
    max-width: 600px;
    height: auto;
  }
`
const Body = styled.div`
  padding: 30px;
  overflow: auto;
`

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`
const Description = styled.div`
  font-size: 0.8em;
  margin-bottom: 20px;
`

const ButtonContainer = styled.div`
  position: fixed;
  right: 17px;
  bottom: 110px;
  z-index: 10;
`
interface Props {
  toggle: (show: boolean) => void
  show: boolean
  source: "Inbound Enquiry" | "Inbound Enquiry - Gifting" | "Inbound Enquiry - General"
  name: string
}

const EnquiryFormModal: FC<Props> = ({ toggle, show, source, name }) => {
  return (
    <StyledModal show={show} onClose={() => toggle(false)} hideHeader>
      <Body>
        <Title>Get in touch</Title>
        <Description>Please get in touch using the form below.</Description>
        <EnquiryForm source={source} name={name} />
      </Body>
      <Portal>
        <ButtonContainer>
          <RoundIconButton
            show={true}
            onClick={() => toggle(true)}
            iconName="mail"
            backgroundColor={"rgb(0, 164, 189)"}
            fontColor={"white"}
          />
        </ButtonContainer>
      </Portal>
    </StyledModal>
  )
}

export default EnquiryFormModal
